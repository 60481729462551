<template>
  <div class="feedback">
    <v-app-bar dense color="white" class="mt-8">
      <v-icon color="deep-orange lighten-1" left @click="goBack">mdi-arrow-left-circle</v-icon>
      <v-toolbar-title>Feedback</v-toolbar-title>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card outlined class="px-5 pt-5 pb-10">
            <v-form ref="form" lazy-validation @submit.prevent="submit">
              <v-text-field type="text" v-model="feedback_data.name" :rules="nameRules" label="Name"></v-text-field>

              <v-text-field
                type="number"
                v-model="feedback_data.contact"
                :rules="mobileRules"
                label="Mobile"
              ></v-text-field>
              <v-text-field type="text" v-model="feedback_data.comment" label="Valuable Feedback"></v-text-field>

              <div>
                <label class="grey--text" for="rating*">Rating:</label>
                <v-rating
                  length="5"
                  v-model="feedback_data.rating"
                  size="40"
                  color="warning"
                  background-color="grey darken-1"
                ></v-rating>

                <v-row dense class="mt-8" align="center" justify="center">
                  <v-btn width="200px" dark tile color="deep-orange darken-1" type="submit" :loading="loader"
                    >Submit</v-btn
                  >
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <!-- feedback dailog box -->
      <div class="text-center">
        <v-dialog v-model="feedback_dailog">
          <v-card tile style="border: 1px solid orangered" class="py-4">
            <div class="text-center">
              <v-icon x-large class="pa-2">mdi-emoticon-happy-outline</v-icon>
              <h1 style="color: orangered">Thank you!</h1>
              <h4 class="grey-text">For your valuable feedback</h4>
            </div>

            <div class="text-center pa-3">
              <v-btn color="orange darken-4" outlined tile @click.prevent="confirmFeedback">OK</v-btn>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template> 

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    feedback_data: {
      name: '',
      contact: '',
      comment: '',
      rating: 0
    },

    nameRules: [(v) => !!v || 'Name is required', (v) => /^[A-Za-z]+$/.test(v) || 'Name must only  character'],
    mobileRules: []
  }),

  methods: {
    submit() {
      if (this.feedback_data.contact) {
        this.mobileRules = [
          (v) => /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(v) || 'Mobile must be 10 digit'
        ]
      }
      if (this.$refs.form.validate() && this.feedback_data.rating > 0) {
        this.$store.dispatch('insertFeedback', this.feedback_data)
        this.feedback_data = {}
        this.$refs.form.resetValidation()
      } else if (this.$refs.form.validate() && this.feedback_data.rating == 0) {
        alert('Please rating here')
      } else {
        this.$refs.form.validate()
      }
    },

    goBack() {
      this.$router.go(-1)
    },
    confirmFeedback() {
      this.$store.dispatch('setFeedbackDialog', false)
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters({
      loader: 'loader',
      feedback_dailog: 'feedback_dailog'
    })
  }
}
</script>

<style>
</style>