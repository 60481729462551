<template>
  <div class="navbar">
    <v-app-bar app fixed color="deep-orange darken-1" dark>
      <v-app-bar-nav-icon @click="$emit('changeDrawer')"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase" v-for="hotel in hotel_info" :key="hotel.id">
        {{ hotel.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-if="this.$route.name != 'Feedback'">
        <v-icon @click.prevent="searchDailog()">mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- search dialog -->
    <v-container fluid>
      <v-dialog v-model="dailog" fullscreen hide-overlay transition="dialog-top-transition">
        <v-card>
          <v-row dense>
            <v-col cols="12" class="px-8" style="position: fixed; background-color: white; z-index: 1">
              <v-text-field
                type="text"
                v-model="searchQuery"
                autofocus
                color="grey"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search..."
                append-icon="mdi-close"
                @click:append="clearInput"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-8 mt-16">
              <v-list>
                <v-list-item v-for="item in filteredResources" :key="item.sub_menu_id" style="min-height: 33px">
                  <v-list-item-icon class="ma-0 mt-2">
                    <v-icon small color="green" v-if="item.menu_type == 'veg'">mdi-square-circle</v-icon>
                    <v-icon small color="red" v-else>mdi-square-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pa-0 ma-0">
                    <v-row dense>
                      <v-col cols="8">
                        <v-list-item-title class="subtitle-2"> {{ item.sub_menu_name }}</v-list-item-title>
                      </v-col>
                      <v-col cols="3" offset="1">
                        <v-list-item-title class="subtitle-2"
                          >Rs. {{ item.price_without_tax }}</v-list-item-title
                        ></v-col
                      >
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <h4 align="center" v-if="filteredResources == ''">Not found</h4>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navbar',
  data() {
    return {
      dailog: false,
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters({
      hotel_info: 'hotel_info',
      sub_menu_list: 'sub_menu_list'
    }),
    filteredResources() {
      let result = null
      if (this.searchQuery.length > 0) {
        result = this.sub_menu_list.filter((item) => {
          return item.sub_menu_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        })
      } else {
        result = this.sub_menu_list
      }

      return result
    }
  },
  methods: {
    searchDailog() {
      this.dailog = true
    },
    clearInput() {
      this.dailog = false
      this.searchQuery = ''
    }
  }
}
</script>

<style>
</style>