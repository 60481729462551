<template>
  <div class="home">
    <!-- <v-app-bar dense fixed class="mt-12" color="white">
      <v-tabs color="deep-orange darken-1" centered show-arrows>
        <v-tab
          v-for="category in main_menu_list"
          :key="category.main_menu_id"
          @click="goto('id' + category.main_menu_id)"
          >{{ category.main_menu_name }}
        </v-tab>
      </v-tabs>
    </v-app-bar> -->
    <v-sheet class="mx-auto stiky py-4" max-width="800">
      <v-slide-group v-model="model" center-active>
        <v-slide-item v-for="category in main_menu_list" :key="category.main_menu_id" v-slot="{ active, toggle }">
          <div class="pl-5" @click="showListItem(category)">
            <v-row align="center" justify="center">
              <v-card
                dark
                elevation="2"
                rounded="circle"
                :color="active ? 'grey darken-4' : 'grey darken-1'"
                class="ma-4"
                height="60"
                width="60"
                @click="toggle"
              >
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <h6 class="headline font-weight-bold grey--grey">{{ category.main_menu_name.charAt(0) }}</h6>
                  <!-- <v-scale-transition>
                    <v-icon v-if="active" color="white" size="20" v-text="'mdi-close-circle-outline'"></v-icon>
                  </v-scale-transition> -->
                </v-row>
              </v-card>
            </v-row>
            <v-row align="center" justify="center" dense>
              <h5 grey--text>{{ category.main_menu_name }}</h5></v-row
            >
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-container fluid class="mt-8">
      <v-row justify="center" v-if="loading" class="pb-16">
        <!-- <h1>Loading</h1> -->
        <v-overlay>
          <v-progress-circular indeterminate size="64"> </v-progress-circular>
        </v-overlay>
      </v-row>
      <v-row justify="center" v-else-if="!main_menu_list" class="pb-16">
        <h1>Data not found</h1>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="px-0 px-2">
          <v-list
            flat
            v-for="category in main_menu_list"
            :key="category.main_menu_id"
            :id="'id' + category.main_menu_id"
            :ref="category.main_menu_id"
            @scroll="listScroll"
            :light="false"
            class="pa-0"
          >
            <v-divider></v-divider>
            <v-list-group
              no-action
              color="black"
              @click="showListItem(category)"
              :value="selectedCategory.main_menu_id === category.main_menu_id ? true : false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="h6 font-weight-medium">{{ category.main_menu_name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-divider></v-divider>
              <v-list color="grey lighten-5">
                <v-list-item
                  v-for="item in sub_menu_list"
                  :key="item.sub_menu_id"
                  v-show="item.main_menu_id == category.main_menu_id"
                  style="min-height: 28px"
                  ><v-list-item-icon class="ma-0 mt-1">
                    <v-icon small color="green" v-if="item.menu_type == 'veg'">mdi-square-circle</v-icon>
                    <v-icon small color="red" v-else>mdi-square-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pa-0 ma-0">
                    <v-row dense>
                      <v-col cols="6">
                        <v-list-item-title class="subtitle-2"> {{ item.sub_menu_name }}</v-list-item-title>
                      </v-col>
                      <v-col cols="4" offset="2">
                        <v-list-item-title class="subtitle-2"
                          >Rs. {{ item.price_without_tax }}</v-list-item-title
                        ></v-col
                      >
                    </v-row>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                      <v-btn color="orange" depressed elevation="2" outlined rounded x-small>ADD</v-btn>
                    </v-list-item-action> -->
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      model: null,
      selectedCategory: {
        main_menu_id: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      main_menu_list: 'main_menu_list',
      sub_menu_list: 'sub_menu_list',
      loading: 'loader'
    })
  },
  methods: {
    goto(ref) {
      console.log(this.$refs[ref][0])
      this.$vuetify.goTo(this.$refs[ref][0], { offset: 130 })
      // this.$refs[ref][0].scrollTo({ top: position, behavior: 'smooth' })
    },
    listScroll(event) {
      console.log('scrolling')
    },
    showListItem(category) {
      this.selectedCategory = category
      setTimeout(() => {
        this.goto(category.main_menu_id)
      }, 500)
    }
  },
  beforeMount() {
    let hotel_id = this.$route.query.hotel_id
    localStorage.setItem('hotel_branch_id', hotel_id)
    this.$store.dispatch('getData', { hotel_branch_id: hotel_id })
  },
  mounted() {
    window.addEventListener('scroll', this.listScroll)
  }
}
</script>

<style>
.stiky {
  position: sticky;
  top: 55px;
  z-index: 1;
}
</style>    