<template>
  <indexed />
</template>

<script>
import indexed from "./containers/Index.vue";
export default {
  name: "App",
  components: {
    indexed,
  },
};
</script>
